import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-6px" }}
      width="274"
      height="18"
      viewBox="0 0 274 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4301 13.3512C44.4273 11.9177 3.20147 13.9968 0.870632 13.7594C-1.57739 13.2563 1.67796 3.3547 3.98275 1.79778C4.76403 1.26615 5.70158 1.13325 7.85011 1.35159C9.69915 1.54146 21.8611 1.21869 54.7923 0.402256C54.7923 0.402256 127.751 -0.0439315 135.004 0.00353545C138.65 0.0225222 135.746 0.573137 140.057 0.402256C149.224 0.0320139 169.172 0.943378 176.139 0.601616C181.725 0.326308 182.923 0.354794 188.236 0.867437C192.051 1.23768 223.42 2.22498 226.167 1.78829C227.001 1.65538 227.613 1.75032 227.6 1.97816C228.042 2.02563 248.16 3.69646 248.265 4.00974C248.382 4.23758 248.994 4.39897 249.606 4.31353C250.96 4.12366 264.32 5.24388 270.062 6.60143C272.432 7.16154 273.969 7.62672 273.995 7.7976C274.19 9.45894 268.773 14.5854 267.367 15.829C264.255 18.5726 264.45 18.4587 254.879 16.9112C241.585 14.7562 241.532 15.6961 235.985 15.2309C234.683 15.1265 225.894 14.4525 212.208 13.7974C184.121 12.4494 184.798 12.8291 174.667 13.1993C173.404 12.5063 168.391 13.9873 165.409 12.7816C164.172 12.2785 155.213 12.0411 153.195 12.4778C151.828 12.7721 105.888 11.2057 100.81 12.6012C91.7601 11.3576 56.1856 13.1234 50.4301 13.3512Z"
        fill="#FFBA00"
      />
    </svg>
  </span>
)

export default titleUnderline
